<template>
  <div class="gallery py-3 text-left">
    <div class="mb-4">
      <p class="text-2xl font-bold">{{ modalTitle }}</p>
      <p v-if="modalDescription">{{ modalDescription }}</p>
    </div>
    <div class="border py-3 bg-white rounded-md px-4 flex justify-between mb-6">
      <div class="flex-1 flex justify-start items-end">
        <div class="flex gap-2 items-end">
          <div class="w-full text-left">
            <label class="text-neutral-500 text-xs font-medium mb-1 pl-3">Search {{ activityName }}...</label>
            <div class="relative">
              <div class="absolute left-4 top-1/2 transform -translate-y-1/2">
                <Search color="neutral-400" />
              </div>
              <TextField type="text" :withIcon="true" :borderEnabled="true" v-model="keyword" placeholder="Type filename" :enterKeyAction="search" />
            </div>
          </div>
          <div class="">
            <Button @action="search" buttonText="Search" type="secondary" size="big" />
          </div>
        </div>
      </div>
    </div>
    <div class="border py-3 px-4 bg-white rounded-md">
      <BreadCrumb :list="breadCrumbList" @clickBreadCrumb="clickBreadCrumb" />
      <div class="grid grid-cols-4 gap-6" v-if="gallery?.length > 0">
        <GalleryCard
          v-for="(file, index) in gallery"
          :key="file.id"
          :file="file"
          :index="index"
          :selected="isFileSelected(file)"
          :fileType="activityType"
          @handleClick="handleClickFolder"
          @handleErrorMessage="handleErrorMessage"
          :currentFile="currentFile"
        />
      </div>
      <div v-else class="py-20 flex items-center justify-center"><span class="text-center">No data available</span></div>
    </div>
    <div class="mt-4 flex justify-between items-center mb-6">
      <div>
        <div class="text-neutral-500 font-medium flex-1 flex gap-2">
          <span>Selected:</span>
          <p v-if="selectedFile" class="text-neutral-300">
            {{ selectedFile.title }}
          </p>
          <p v-else class="text-neutral-300">No {{ activityName }} selected.</p>
        </div>
        <p v-if="errorMessage" class="text-system-error">{{ errorMessage }}</p>
      </div>
      <div class="flex-1 flex justify-end items-end" v-show="gallery?.length > 0">
        <pagination :currentPage="page" :totalCount="totalRecords" :pageSize="pageSize" @onPageChange="onPageChange" />
      </div>
    </div>
    <div class="flex justify-center items-center">
      <Button :buttonText="buttonText" @action="select()" :disabled="isButtonDisable" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Search: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Search'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/Pagination/Pagination'),
    GalleryUpload: () => import(/* webpackChunkName: "GalleryUpload" */ '@/views/gallery/GalleryUpload'),
    GalleryCard: () => import(/* webpackChunkName: "GalleryCard" */ '@/components/GalleryModal/GalleryCard'),
    BreadCrumb: () => import(/* webpackChunkName: "BreadCrumb" */ '@/components/Breadcrumb/Breadcrumb'),
    FileDetails: () => import(/* webpackChunkName: "FileDetails" */ '@/views/gallery/FileDetails')
  },
  props: {
    activityType: {
      type: String,
      default: 'VIDEO'
    },
    modalTitle: {
      type: String,
      default: ''
    },
    modalDescription: {
      type: String,
      default: ''
    },
    currentFile: {
      type: Object,
      default: () => null
    },
    buttonText: {
      type: String,
      default: 'Select'
    }
  },
  data() {
    return {
      keyword: '',
      totalRecords: 0,
      totalRecordsInFolder: 0,
      currentPage: 1,
      currentPageInFolder: 1,
      pageSize: 12,
      selectedVideo: null,
      selectedVideoState: [],
      selectedFile: null,
      breadCrumbList: [],
      isFileValid: false,
      mediaParentId: null,
      errorMessage: '',
      galleryInFolder: [],
      currentPageInFolder: 1
    }
  },
  mounted() {
    this.getGallery()
  },
  computed: {
    ...mapGetters('gallery', ['gallery']),
    page() {
      if (this.mediaParentId) return this.currentPageInFolder
      return this.currentPage
    },
    start() {
      return (this.currentPage - 1) * this.pageSize + 1
    },
    end() {
      const lastOnPage = (this.currentPage - 1) * this.pageSize + this.pageSize
      return lastOnPage > this.totalRecords ? this.totalRecords : lastOnPage
    },
    clientId() {
      return localStorage.getItem('client')
    },
    activityName() {
      return this.activityType.toLowerCase()
    },
    isButtonDisable() {
      if (this.selectedFile && this.errorMessage) return true
      if (!this.selectedFile && !this.errorMessage) return true
      return false
    }
  },
  methods: {
    ...mapActions('gallery', ['GET_GALLERY', 'GET_GALLERY_V3', 'GET_GALLERY_DETAILS_V3']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    handleErrorMessage(message) {
      this.errorMessage = message
    },
    handleClickFolder(file, index) {
      if (file.mimeType === 'FOLDER') {
        this.keyword = ''
        this.currentPageInFolder = 1
        this.mediaParentId = file.id
        this.getGalleryDetails(file.id)
        this.breadCrumbList.push(file)
        return
      }
      this.selectedFile = file
    },
    clickBreadCrumb(path, index) {
      if (path === 'HOME') {
        this.keyword = ''
        this.mediaParentId = null
        this.currentPage = 1
        this.currentPageInFolder = 1
        this.breadCrumbList = []
        this.getGallery()
        return
      }
      this.mediaParentId = path.id
      this.breadCrumbList = this.breadCrumbList.filter((p, pathIdx) => pathIdx <= index)
      this.currentPageInFolder = 1
      this.getGalleryDetails(path.id)
    },
    isFileSelected(file) {
      if (!file) return false
      if (!this.selectedFile) return false
      return file?.id === this.selectedFile?.id
    },
    select() {
      this.$emit('selectVideo', this.selectedFile)
      this.$emit('close')
    },
    onPageChange(page) {
      if (this.mediaParentId) {
        this.currentPageInFolder = page
        this.getGalleryDetails(this.mediaParentId)
        return
      }
      this.currentPage = page
      this.getGallery()
    },
    search() {
      if (this.mediaParentId) {
        this.currentPage = 1
        this.getGalleryDetails(this.mediaParentId)
        return
      }
      this.currentPage = 1
      this.getGallery()
    },
    getGallery() {
      this.showLoading()
      let isCommit = true
      this.GET_GALLERY_V3({
        params: {
          customerId: this.clientId,
          query: this.keyword,
          page: this.currentPage - 1,
          limit: this.pageSize,
          direction: 'DESC'
        },
        isCommit
      }).then((response) => {
        this.totalRecords = response.data.pagination.total
        response.data.data.forEach((item, index) => {
          this.selectedVideoState.push({ active: false })
        })
        this.hideLoading()
      })
    },
    getGalleryDetails(mediaParentId) {
      this.showLoading()
      let isCommit = true
      this.GET_GALLERY_DETAILS_V3({
        params: {
          customerId: this.clientId,
          page: this.currentPageInFolder - 1,
          limit: this.pageSize,
          query: this.keyword,
          direction: 'DESC'
        },
        mediaParentId,
        isCommit
      }).then((response) => {
        this.totalRecords = response.data.pagination.total
        this.hideLoading()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.file-card {
  box-shadow: 0px 2px 4px rgba(39, 40, 51, 0.12);
}
.px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.p-3 {
  padding: 12px !important;
}
.p-5 {
  padding: 1.25rem !important;
}
.mb-4 {
  margin-bottom: 1rem !important;
}
p {
  margin: 0;
}
a {
  color: #58595b;
}
button {
  margin: 0 !important;
  --tw-border-opacity: 1;
  border-color: rgba(253, 233, 210, var(--tw-border-opacity)) !important;
  &:hover {
    background: #fde9d2;
  }
}
</style>
