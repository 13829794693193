var render = function () {
  var _vm$gallery, _vm$gallery2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gallery py-3 text-left"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('p', {
    staticClass: "text-2xl font-bold"
  }, [_vm._v(_vm._s(_vm.modalTitle))]), _vm.modalDescription ? _c('p', [_vm._v(_vm._s(_vm.modalDescription))]) : _vm._e()]), _c('div', {
    staticClass: "border py-3 bg-white rounded-md px-4 flex justify-between mb-6"
  }, [_c('div', {
    staticClass: "flex-1 flex justify-start items-end"
  }, [_c('div', {
    staticClass: "flex gap-2 items-end"
  }, [_c('div', {
    staticClass: "w-full text-left"
  }, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3"
  }, [_vm._v("Search " + _vm._s(_vm.activityName) + "...")]), _c('div', {
    staticClass: "relative"
  }, [_c('div', {
    staticClass: "absolute left-4 top-1/2 transform -translate-y-1/2"
  }, [_c('Search', {
    attrs: {
      "color": "neutral-400"
    }
  })], 1), _c('TextField', {
    attrs: {
      "type": "text",
      "withIcon": true,
      "borderEnabled": true,
      "placeholder": "Type filename",
      "enterKeyAction": _vm.search
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  })], 1)]), _c('div', {}, [_c('Button', {
    attrs: {
      "buttonText": "Search",
      "type": "secondary",
      "size": "big"
    },
    on: {
      "action": _vm.search
    }
  })], 1)])])]), _c('div', {
    staticClass: "border py-3 px-4 bg-white rounded-md"
  }, [_c('BreadCrumb', {
    attrs: {
      "list": _vm.breadCrumbList
    },
    on: {
      "clickBreadCrumb": _vm.clickBreadCrumb
    }
  }), ((_vm$gallery = _vm.gallery) === null || _vm$gallery === void 0 ? void 0 : _vm$gallery.length) > 0 ? _c('div', {
    staticClass: "grid grid-cols-4 gap-6"
  }, _vm._l(_vm.gallery, function (file, index) {
    return _c('GalleryCard', {
      key: file.id,
      attrs: {
        "file": file,
        "index": index,
        "selected": _vm.isFileSelected(file),
        "fileType": _vm.activityType,
        "currentFile": _vm.currentFile
      },
      on: {
        "handleClick": _vm.handleClickFolder,
        "handleErrorMessage": _vm.handleErrorMessage
      }
    });
  }), 1) : _c('div', {
    staticClass: "py-20 flex items-center justify-center"
  }, [_c('span', {
    staticClass: "text-center"
  }, [_vm._v("No data available")])])], 1), _c('div', {
    staticClass: "mt-4 flex justify-between items-center mb-6"
  }, [_c('div', [_c('div', {
    staticClass: "text-neutral-500 font-medium flex-1 flex gap-2"
  }, [_c('span', [_vm._v("Selected:")]), _vm.selectedFile ? _c('p', {
    staticClass: "text-neutral-300"
  }, [_vm._v(" " + _vm._s(_vm.selectedFile.title) + " ")]) : _c('p', {
    staticClass: "text-neutral-300"
  }, [_vm._v("No " + _vm._s(_vm.activityName) + " selected.")])]), _vm.errorMessage ? _c('p', {
    staticClass: "text-system-error"
  }, [_vm._v(_vm._s(_vm.errorMessage))]) : _vm._e()]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ((_vm$gallery2 = _vm.gallery) === null || _vm$gallery2 === void 0 ? void 0 : _vm$gallery2.length) > 0,
      expression: "gallery?.length > 0"
    }],
    staticClass: "flex-1 flex justify-end items-end"
  }, [_c('pagination', {
    attrs: {
      "currentPage": _vm.page,
      "totalCount": _vm.totalRecords,
      "pageSize": _vm.pageSize
    },
    on: {
      "onPageChange": _vm.onPageChange
    }
  })], 1)]), _c('div', {
    staticClass: "flex justify-center items-center"
  }, [_c('Button', {
    attrs: {
      "buttonText": _vm.buttonText,
      "disabled": _vm.isButtonDisable
    },
    on: {
      "action": function action($event) {
        return _vm.select();
      }
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }